const data = [
    {
      year: 2023,
      items: [
        {
          title: 'Маникюр, моделирование, дизайн, педикюр и подология',
          name: 'Раиса Нурмухаметова',
          country: 'Казахстан',
          name2: 'Марина Левина',
          country2: 'Россия',
          name3: 'Юлия Кравчук',
          country3: 'Украина',
        },
        {
          title: 'Перманентный макияж, визаж, а также Lash и Brow направление',
          name: 'Наталья Чернова',
          country: 'Беларусь',
          name2: 'Виктория Самойлова',
          country2: 'Россия',
          name3: 'Ирина Свиридова',
          country3: 'Казахстан',
        },
        {
          title: 'Парикмахерское искусство',
          name: 'Дарья Мельникова',
          country: 'Беларусь',
          name2: 'Людмила Казакова',
          country2: 'Россия',
          name3: 'Татьяна Мороз',
          country3: 'Украина',
        },
        {
          title: 'Инновации в косметологии',
          name: 'Карина Абдуллина',
          country: 'Казахстан',
          name2: 'Ирина Белоусова',
          country2: 'Украина',
          name3: 'Алла Петрова',
          country3: 'Россия',
        },
        {
          title: 'Массаж и телесные терапии',
          name: 'Лариса Кузнецова',
          country: 'Казахстан',
          name2: 'Яна Калинкина',
          country2: 'Беларусь',
          name3: 'Ольга Бондаренко',
          country3: 'Украина',
        },
        {
          title: 'Мастерство в области стиля и имиджа',
          name: 'Марина Ковальчук',
          country: 'Украина',
          name2: 'Светлана Петрова',
          country2: 'Россия',
          name3: 'Анна Киреева',
          country3: 'Беларусь',
        },
        {
          title: 'Фотография и видеография в индустрии красоты',
          name: 'Дарья Махиня',
          country: 'Украина',
          name2: 'Алексей Петров',
          country2: 'Казахстан',
          name3: 'Сергей Левченко',
          country3: 'Украина',
        },

      ],
    },
    {
        year: 2022,
        items: [
          {
            title: 'Маникюр, моделирование, дизайн, педикюр и подология',
            name: 'Валентина Сорокина',
            country: 'Беларусь',
            name2: 'Галина Иваненко',
            country2: 'Россия',
            name3: 'Светлана Терентьева',
            country3: 'Казахстан',
          },
          {
            title: 'Перманентный макияж, визаж, а также Lash и Brow направление',
            name: 'Маргарита Левченко',
            country: 'Украина',
            name2: 'Карина Шевченко',
            country2: 'Казахстан',
            name3: 'Анна Смирнова',
            country3: 'Россия',
          },
          {
            title: 'Парикмахерское искусство',
            name: 'Татьяна Резник',
            country: 'Украина',
            name2: 'Ольга Крупская',
            country2: 'Беларусь',
            name3: 'Елена Антонова',
            country3: 'Россия',
          },
          {
            title: 'Инновации в косметологии',
            name: 'Виктория Губанова',
            country: 'Беларусь',
            name2: 'Екатерина Вольская',
            country2: 'Россия',
            name3: 'Анжела Кучеренко',
            country3: 'Украина',
          },
          {
            title: 'Массаж и телесные терапии',
            name: 'Маргарита Королева',
            country: 'Беларусь',
            name2: 'Екатерина Шестакова',
            country2: 'Украина',
            name3: 'Нина Иванова',
            country3: 'Россия',
          },
          {
            title: 'Мастерство в области стиля и имиджа',
            name: 'Александра Михайлова',
            country: 'Россия',
            name2: 'Елена Сергеева',
            country2: 'Украина',
            name3: 'Оксана Малышева',
            country3: 'Беларусь',
          },
          {
            title: 'Фотография и видеография в индустрии красоты',
            name: 'Гульшат Асанова',
            country: 'Казахстан',
            name2: 'Павел Макаров',
            country2: 'Россия',
            name3: 'Юлия Тимошенко',
            country3: 'Украина',
          },

        ],
      },
      {
        year: 2021,
        items: [
          {
            title: 'Маникюр, моделирование, дизайн, педикюр и подология',
            name: 'Анна Костенко',
            country: 'Украина',
            name2: 'Елена Волкова',
            country2: 'Россия',
            name3: 'Нина Жук',
            country3: 'Беларусь',
          },
          {
            title: 'Перманентный макияж, визаж, а также Lash и Brow направление',
            name: 'Жанна Алиева',
            country: 'Казахстан',
            name2: 'Виктория Самойлова',
            country2: 'Россия',
            name3: 'Елена Березовская',
            country3: 'Украина',
          },
          {
            title: 'Парикмахерское искусство',
            name: 'Марина Громова',
            country: 'Беларусь',
            name2: 'Светлана Алексеева',
            country2: 'Россия',
            name3: 'Надежда Кузнецова',
            country3: 'Казахстан',
          },
          {
            title: 'Инновации в косметологии',
            name: 'Ирина Жукова',
            country: 'Россия',
            name2: 'Наталья Рыбакова',
            country2: 'Украина',
            name3: 'Лилия Горбунова',
            country3: 'Беларусь',
          },
          {
            title: 'Массаж и телесные терапии',
            name: 'Мария Васильева',
            country: 'Россия',
            name2: 'Алла Ткаченко',
            country2: 'Украина',
            name3: 'Яна Калинкина',
            country3: 'Беларусь',
          },
          {
            title: 'Мастерство в области стиля и имиджа',
            name: 'Ксения Бородина',
            country: 'Беларусь',
            name2: 'Ирина Василенко',
            country2: 'Украина',
            name3: 'Алина Романова',
            country3: 'Россия',
          },
          {
            title: 'Фотография и видеография в индустрии красоты',
            name: 'Леонид Остапенко',
            country: 'Украина',
            name2: 'Виктор Соболев',
            country2: 'Россия',
            name3: 'Егор Тарасов',
            country3: 'Беларусь',
          },

        ],
      },
      {
        year: 2020,
        items: [
          {
            title: 'Маникюр, моделирование, дизайн, педикюр и подология',
            name: 'Инга Аванесян',
            country: 'Украина',
            name2: 'Ирина Свиридова',
            country2: 'Россия',
            name3: 'Иванна Лукина',
            country3: 'Беларусь',
          },
          {
            title: 'Перманентный макияж, визаж, а также Lash и Brow направление',
            name: 'Дарья Махиня',
            country: 'Украина',
            name2: 'Светлана Морозова',
            country2: 'Россия',
            name3: 'Наталья Петрова',
            country3: 'Молдова',
          },
          {
            title: 'Парикмахерское искусство',
            name: 'Лида Курбанова',
            country: 'Казахстан',
            name2: 'София Руденко',
            country2: 'Украина',
            name3: 'Татьяна Мороз',
            country3: 'Россия',
          },
          {
            title: 'Инновации в косметологии',
            name: 'Карина Абдуллина',
            country: 'Казахстан',
            name2: 'Вера Сидорова',
            country2: 'Россия',
            name3: 'Ирина Белоусова',
            country3: 'Украина',
          },
          {
            title: 'Массаж и телесные терапии',
            name: 'Лариса Кузнецова',
            country: 'Казахстан',
            name2: 'Василий Теркин',
            country2: 'Россия',
            name3: 'Сергей Петров',
            country3: 'Молдова',
          },
          {
            title: 'Мастерство в области стиля и имиджа',
            name: 'Алина Садыкова',
            country: 'Казахстан',
            name2: 'Мария Полищук',
            country2: 'Украина',
            name3: 'Вера Захарова',
            country3: 'Россия',
          },
          {
            title: 'Фотография и видеография в индустрии красоты',
            name: 'Дмитрий Сергеев',
            country: 'Беларусь',
            name2: 'Иван Чернов',
            country2: 'Россия',
            name3: 'Сергей Левченко',
            country3: 'Украина',
          },

        ],
      },
      {
        year: 2019,
        items: [
          {
            title: 'Маникюр, моделирование, дизайн, педикюр и подология',
            name: 'Лариса Гордеева',
            country: 'Россия',
            name2: 'Олеся Кучер',
            country2: 'Украина',
            name3: 'Тамара Соловьева',
            country3: 'Беларусь',
          },
          {
            title: 'Перманентный макияж, визаж, а также Lash и Brow направление',
            name: 'Екатерина Васильева',
            country: 'Россия',
            name2: 'Мария Петрова',
            country2: 'Россия',
            name3: 'Наталья Чернова',
            country3: 'Беларусь',
          },
          {
            title: 'Парикмахерское искусство',
            name: 'Татьяна Резник',
            country: 'Украина',
            name2: 'Людмила Казакова',
            country2: 'Россия',
            name3: 'Марина Громова',
            country3: 'Беларусь',
          },
          {
            title: 'Инновации в косметологии',
            name: 'Карина Абдуллина',
            country: 'Казахстан',
            name2: 'Ирина Белоусова',
            country2: 'Украина',
            name3: 'Алла Петрова',
            country3: 'Россия',
          },
          {
            title: 'Массаж и телесные терапии',
            name: 'Елена Каримова',
            country: 'Казахстан',
            name2: 'Наталья Морозова',
            country2: 'Россия',
            name3: 'Ольга Бондаренко',
            country3: 'Украина',
          },
          {
            title: 'Мастерство в области стиля и имиджа',
            name: 'Александра Михайлова',
            country: 'Россия',
            name2: 'Марина Ковальчук',
            country2: 'Украина',
            name3: 'Ксения Бородина',
            country3: 'Беларусь',
          },
          {
            title: 'Фотография и видеография в индустрии красоты',
            name: 'Антон Королев',
            country: 'Россия',
            name2: 'Дмитрий Новиков',
            country2: 'Беларусь',
            name3: 'Сергей Левченко',
            country3: 'Украина',
          },

        ],
      },
  ];

  export default data;
